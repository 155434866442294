'use strict';

const errorManager = require('../components/error');
const cssConstants = require('../utils/cssConstants');

const selectors = {
    inputField: '.js-postal-code-input',
    postalCodeForm: '.js-postal-code-form',
    postalCodeErrorMsg: '.js-postal-code-error',
    resultSection: '.js-result',
    resultContainer: '.js-result-container',
    suggestion: '.js-postal-code-suggestion',
    depotField: '.js-depot-field',
    ncpoField: '.js-ncpo-field',
    zipField: '.js-zip-field',
    communeField: '.js-commune-field',
    addressCity: '.js-address-city',
    addressPostalCode: '.js-address-postalcode',
    addressNcpo: '.js-address-ncpo',
    groupRow: '.form-group-row',
    fieldContainer: '.js-field-container',
    billingForm: '.js-billing-form'
};

const classNames = {
    valid: 'valid'
}

const MIN_SEARCH_SYMBOLS = 2;

/**
 * Change regular characters to bold from text
 * @param {string} text - text which characters need to be changed to bold
 * @param {string} characters - symbols of text which should be changed to bold
 * @returns {string} - text with match characters are in bold font
 */
function boldMatchCharacters(text, characters) {
    var regEx = new RegExp(characters.trim(), 'gi');
    return text.replace(regEx, '<span class="highlighted">$&</span>');
}

/**
 * Show suggestion droplist
 * @param {Object} input - input for postal code search
 * @param {string} suggestions - array of suggested postal codes received from controller
 * @param {string} isBillingField - detect if input is billing field
 */
function showResult(input, suggestions, isBillingField) {
    var inputText = input.val();
    var $resultSection = input.closest(selectors.fieldContainer).find(selectors.resultSection);
    var firstValue = suggestions.length && (suggestions[0].PostalCode + ' - ' + suggestions[0].Name).toLowerCase();
    $resultSection.html('');

    if (suggestions.length && isBillingField) {
        var $containerRow = input.closest(selectors.groupRow);
        var postalCodeValue = $containerRow.find(selectors.addressPostalCode).val();
        var cityValue = $containerRow.find(selectors.addressCity).val();

        if (postalCodeValue === suggestions[0].PostalCode && cityValue === suggestions[0].Name && suggestions[0].NCPO) {
            $containerRow.addClass(classNames.valid);
        } else {
            $containerRow.removeClass(classNames.valid);
        }
    }

    if (suggestions.length === 1 && firstValue === inputText.toLowerCase() && !isBillingField) {
        var suggestion = suggestions[0];
        $(selectors.depotField).val(suggestion.depot);
        $(selectors.zipField).val(suggestion.PostalCode);
        $(selectors.communeField).val(suggestion.Name);
        $(selectors.ncpoField).val(suggestion.NCPO);
        $(selectors.resultContainer).addClass(cssConstants.HIDDEN);
    } else if (inputText.length >= MIN_SEARCH_SYMBOLS) {
        input.closest(selectors.fieldContainer).find(selectors.resultContainer).removeClass(cssConstants.HIDDEN);
        suggestions.forEach(function (e) {
            var displayText = boldMatchCharacters(e.PostalCode + ' - ' + e.Name, inputText);
            $resultSection.append(`
                <div class="js-postal-code-suggestion postal-code-suggestion"
                    data-zip="${e.PostalCode}"
                    data-commune="${e.Name}"
                    data-ncpo="${e.NCPO}"
                    data-value="${e.PostalCode + ' - ' + e.Name.toUpperCase()}"
                >
                    ${displayText}
                </div>`
            );
        });
    }
}

/**
 * Hide suggestion droplist
 * @param {Object} input - input for what results are clear
 */
function clearResult(input) {
    if  (input) {
        input.closest(selectors.fieldContainer).find(selectors.resultSection).html('');
        input.closest(selectors.fieldContainer).find(selectors.resultContainer).addClass(cssConstants.HIDDEN);
    } else {
        $(selectors.resultSection).html('');
        $(selectors.resultContainer).addClass(cssConstants.HIDDEN);
    }
}

/**
 * Updates values for postal code form
 * @param {element} target - element from which data is taken
 */
function updateForm(target) {
    if (target) {
        $(selectors.depotField).val(target.data('depot'));
        $(selectors.zipField).val(target.data('zip'));
        $(selectors.communeField).val(target.data('commune'));
        $(selectors.ncpoField).val(target.data('ncpo'));
    } else {
        $(selectors.depotField).val('');
        $(selectors.zipField).val('');
        $(selectors.communeField).val('');
        $(selectors.ncpoField).val('');
    }
}

module.exports = {
    submitForm: function () {
        $(selectors.postalCodeForm).on('submit', function (e) {
            e.preventDefault();

            var $form = $(this);
            var url = $form.attr('action');

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    if (data.success) {
                        window.location.href = data.redirectUrl;
                    } else {
                        errorManager.showError(selectors.inputField, data.message);
                    }
                },
                error: function (err) {
                    errorManager.showError(selectors.inputField, err);
                }
            });
        });
    },
    validatePostalCode: function () {
        $(`${selectors.inputField}, ${selectors.addressCity}, ${selectors.addressPostalCode}`).on('input', function (e) {
            e.preventDefault();

            var $input = $(this);
            var inputValue = $input.val();
            var url = $input.data('url');
            var searchAttribute = $input.data('search-attr');
            var isBillingField = $input.is(selectors.addressCity) || $input.is(selectors.addressPostalCode);

            $input.closest(selectors.groupRow).removeClass(classNames.valid);

            if (!isBillingField) {
                updateForm(null);
            }

            if (inputValue.length >= MIN_SEARCH_SYMBOLS) {
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    data: {
                        st: inputValue,
                        sa: searchAttribute
                    },
                    success: function (data) {
                        if (data.success) {
                            var suggestions = data.suggestions;

                            if (suggestions && suggestions.length) {
                                errorManager.hideError($input);
                                showResult($input, suggestions, isBillingField);
                            } else {
                                clearResult($input);
                                if ($input.val().length >= MIN_SEARCH_SYMBOLS) {
                                    errorManager.showError($input, data.message);
                                }
                            }
                        } else {
                            clearResult($input);
                            if ($input.val().length >= MIN_SEARCH_SYMBOLS) {
                                errorManager.showError($input, data.message);
                            }
                        }
                    },
                    error: function (err) {
                        errorManager.showError($input, err);
                    }
                });
            } else {
                clearResult($input);
                errorManager.hideError($input);
            }
        });
    },
    suggestionsSelectorHandle: function () {
        $('body').on('click', function (e) {
            var $target = $(e.target);
            var isInputClicked = $target.is(selectors.inputField) || $target.is(selectors.addressCity) || $target.is(selectors.addressPostalCode);

            $(selectors.resultContainer).addClass(cssConstants.HIDDEN);

            // show suggestions if input focused
            if (isInputClicked && $target.closest(selectors.fieldContainer).find(selectors.suggestion).length) {
                $target.closest(selectors.fieldContainer).find(selectors.resultContainer).removeClass(cssConstants.HIDDEN);
                errorManager.hideError($target);
            }

            // populate data if suggestion is selected
            var $suggestionElement = $target.closest(selectors.suggestion);
            if ($suggestionElement.length) {
                var ncpo = $suggestionElement.data('ncpo');
                var $groupRow = $target.closest(selectors.groupRow);
                $target.closest(selectors.fieldContainer).find(selectors.inputField).val($suggestionElement.data('value'));
                $groupRow.find(selectors.addressCity).val($suggestionElement.data('commune'));
                $groupRow.find(selectors.addressPostalCode).val($suggestionElement.data('zip'));
                $groupRow.find(selectors.addressNcpo).val(ncpo);
                if (ncpo) {
                    errorManager.hideError($groupRow.find(`${selectors.addressCity}, ${selectors.addressPostalCode}`));
                    $target.closest(selectors.groupRow).addClass(classNames.valid);
                }
                updateForm($suggestionElement);
                clearResult();
            }
        });
    }
};
